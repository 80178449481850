import React from 'react'

//assets
import heroimg from '../../Assets/HomePage/heroimg.png'

function HeroSection() {
  return (
    <div className='bg-[#1E242F] w-full py-16'>
      <div className='container mx-auto flex items-center justify-between md:flex-row flex-col'>


        <div className='flex flex-col'>
          <div className='flex flex-col space-y-4 text-center md:text-start '>
            <p className='text-[#1080F7] font-light text-[35px]'>Brz & Visok Kvalitet</p>
            <p className='text-[#fff] font-bold text-[35px] md:text-[70px] lg:text-[50px] leading-tight'>Popravljamo vaše <br/>pametne telefone</p>
            <p className='text-[#8491A4] text-[16px]'>Vaš servis za visokokvalitetne popravke pametnih telefona u Pomoravlju i okolini.</p>
          </div>

          <div className='hidden md:flex gap-10 mt-10'>
           
            {/* <button className='border-[1px] border-[#1080f7] bg-[#1080f7] rounded-3xl py-3 px-10  text-[22px] border-[#FFFFFF38] text-[#fff]'>Usluge</button> */}
            <a href='#kontakt' className='border-[1px] rounded-full py-3 px-10 text-[22px] border-[#1080f7] bg-[#1080f7]  text-[#fff]' >Kontakt</a>
          </div>
        </div>

        <div>
            <img draggable='false' className='w-[300px] md:w-[650px]' src={heroimg} alt="heorslika" />
        </div>

        <div className='md:hidden flex gap-10 mt-10'>
           
            {/* <button className='border-[1px] border-[#1080f7] bg-[#1080f7] rounded-3xl py-3 px-10  text-[22px] border-[#FFFFFF38] text-[#fff]'>Usluge</button> */}
            <a href='#kontakt' className='border-[1px] rounded-full py-3 px-10 text-[22px] border-[#1080f7] bg-[#1080f7]  text-[#fff]' >Kontakt</a>
          </div>
      </div>
    </div>
  )
}

export default HeroSection
