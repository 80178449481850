import React from 'react'
import Apointment from '../Components/HomePage/Apointment'
import BelowHero from '../Components/HomePage/BelowHero'
import Company from '../Components/HomePage/Company'
import HeroSection from '../Components/HomePage/HeroSection'
import Kontakt from '../Components/HomePage/Kontakt'
// import Procedura from '../Components/HomePage/Procedura'
import Trust from '../Components/HomePage/Trust'

function Home() {
  return (
    <div>
      <HeroSection/>
      <BelowHero/>
      <Company/>
      <Trust/>
      {/* <Procedura/> */}
      <Apointment/>
      <Kontakt/>
    </div>
  )
}

export default Home
