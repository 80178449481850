import React from 'react'

//Assets
import email from '../../Assets/NavBar/contactmailt.png'
import phone from '../../Assets/NavBar/contactphone.png'

// import dark from '../../Assets/NavBar/dark.png'
// import light from '../../Assets/NavBar/light.png'
import location from '../../Assets/NavBar/location.png'

function BlueBar() {
  return (
    <div className='hidden md:block w-full bg-[#1080F7]  text-[#fff] ' >
      <div className='flex justify-between items-center container mx-auto '>
            <div className='flex py-4 gap-10'>

      <div className='flex justify-center items-center gap-2 font-medium'>
          <img className='w-[20px] invert' src={email} alt="" />
          <p>imobileserviscuprija@gmail.com</p>
      </div>


      <div className='flex justify-center items-center gap-2 font-medium'>
          <img className='w-[20px] invert' src={phone} alt="" />
          <p>064 194 28 24</p>
      </div>


      </div>

        <div className='flex items-center justify-center gap-10 '>
          <div className='flex justify-center items-center gap-2 font-light'>
              <img className='w-[20px] invert' src={location} alt="" />
              <p>Ćuprija, Miodraga Novakovica Džudže 2</p>

          </div>

          {/* <div>
              <img  className='w-[30px] invert' src={light} alt="" />
          </div> */}
         </div>
      </div>
      
    </div>
  )
}

export default BlueBar
