import React from 'react'
//assets
import hand from '../../Assets/HomePage/Vectorhand.svg'
import handtwo from '../../Assets/HomePage/Vectorhandtwo.png'
import customers from '../../Assets/HomePage/customers.png'

function BelowHero() {
  return (
    <div className='md:block hidden w-full bg-[#313C42]'>
        <div className='mx-auto container flex items-center justify-center py-6 gap-24 flex-col md:flex-row'>



            <div className='flex flex-row gap-8 md:border-r-2 md:pr-16 items-center justify-center'>
                <img draggable='false' className='w-[90px]  border-[1px] rounded-full px-6 py-6' src={hand} alt="" />
                <div className='flex flex-col space-y-5'>
                    <p className='text-[#fff]'>Usluge za: </p>
                    <p className='text-[#7E8B9E]'>iPhone, iPad, Apple Watch<br/>
                     Samsung, Huawei, Shaomi...</p>
                     </div>
                
            </div>




            <div className='flex flex-row gap-8 md:border-r-2 md:pr-16 items-center justify-center'>
                <img draggable='false' className='w-[90px] h-[90px] border-[1px] rounded-full px-7 py-6 ' src={handtwo} alt="" />
                <div className='flex flex-col space-y-2'>
                    <p className='text-[#fff]'>Lokacija</p>
                    <div className='text-[#7E8B9E]'>
                        <p >Ćuprija</p>
                        <p >  Miodraga Novakovica Džudže 2 </p>
                        <p > 35230 Srbija</p>
                    </div>
                    
                   
                     
                </div>
                
            </div>

            <div className='flex items-center justify-center'>
                <img draggable='false' src={customers} alt="" />
            </div>
        </div>
        
    </div>
  )
}

export default BelowHero