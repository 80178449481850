import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

//assets
import mapa from '../../Assets/HomePage/unnamed.png'
import instagram from '../../Assets/HomePage/ig.png'
import facebook from '../../Assets/HomePage/fb.png'

function Kontakt() {

  let inputsz = document.querySelectorAll('#ime, #email, #poruka')

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_pum5jzb', 'template_pba9wbm', form.current, '3phE71CG3AREKm42k')
      .then((result) => {
          console.log(result.text);
          inputsz.forEach(inputszf => {inputszf.value = ''})
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <div id='kontakt' className='w-full bg-[#1E242F] py-24'>
        <div className='container mx-auto flex justify-center  md:justify-between items-center md:items-start flex-col md:flex-row px-4 md:px-0 '>
            <div>
              <div className='text-center md:text-start flex flex-col items-center md:items-start '>
                <p className='text-[#1080F7]'>Zdravo</p>
                <p className='text-[35px] md:text-[64px] lg:text-[45px] text-[#fff] font-semibold text-center md:text-start'>Kontaktirajte nas</p>
                <div className='text-[#8491A4] space-y-4 mt-12 text-center md:text-start'>
                  <p>Ćuprija, Miodraga Novakovica Džudže 2/2, 35230 Srbija</p>
                  <p>Email: imobileserviscuprija@gmail.com</p>
                  <p>Telefon: 064 194 28 24</p>
                </div>
                
              </div>


              <div className='flex items-center justify-center md:justify-start gap-4 my-4'>
                  <a href="https://www.facebook.com">
                    <img src={facebook} alt="fejs" />
                  </a>
                  <a href="https://www.instagram.com">
                    <img src={instagram} alt="insta" />
                  </a>
                 
                  
              </div>

              <div className='p-2 md:p-0 '>
                <a href="https://goo.gl/maps/2fyysEKn2WHxVYdk7">
                     <img className='rounded-3xl' src={mapa} alt="mapa" />
                </a>
                
              </div>
            </div>


            <div>
              <form ref={form} onSubmit={sendEmail}>
                <div className='flex items-center justify-center flex-col space-y-6 mt-12 md:mt-0'>
                  <div className='flex flex-col'>
                    <label className='text-[#fff]' >Ime *</label>
                    <input id='ime' className='rounded-xl text-[20px] w-[350px] md:w-[550px] lg:w-[400px] py-2 px-3 outline-none' placeholder='Ime' type="text" name='user_name' />
                  </div>

                  <div className='flex flex-col'>
                    <label className='text-[#fff]' >E-mail *</label>
                    <input id='email' className=' rounded-xl text-[20px] w-[350px] md:w-[550px] lg:w-[400px] py-2 px-3 outline-none' placeholder='E-Mail' type="email" name='user_email'/>
                  </div>

                  <div className='flex flex-col'>
                    <label className='text-[#fff]'>Poruka *</label>
                    <textarea id='poruka' style={{resize: 'none'}} className=' rounded-xl text-[20px] py-2 px-3 outline-none w-[350px] md:w-[550px] lg:w-[400px]' placeholder='Poruka' type="text" cols="51" rows="5" name="message"></textarea>
                  </div>
                  <button className='bg-[#1080F7] transition-all ease-in-out w-full py-2 rounded-3xl text-[#fff] text-[18px] border-[#1080F7] border-2 hover:bg-[transparent]' >Pošalji</button>
                </div>
              </form>
            </div>
        </div>
    </div>
  )
}

export default Kontakt