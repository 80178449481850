import React from 'react'
import Main from '../Components/AboutStrana/Main'

function About() {
  return (
    <div>
      <Main/>
    </div>
  )
}

export default About
