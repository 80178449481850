import React from 'react'
import {Link} from 'react-router-dom'
import logo from '../../Assets/NavBar/logo.png'

function FooterUp() {
  return (
    <div className='w-full bg-[#313C42] py-10'>
      <div className='container mx-auto flex justify-between items-center flex-col md:flex-row'>
        <div className='flex flex-col justify-center items-center'>
            <img className='w-[350px]' src={logo} alt="" />
            <p className='text-[#fff]'>Sa vama od 2015.</p>
        </div>
        <div className='flex justify-center md:justify-between items-center mt-12 md:mt-0 md:items-start md:gap-44 gap-20 flex-col md:flex-row'>
            <div className='flex flex-col items-center justify-center space-y-2 text-center'>
                <p className='text-[#fff] text-[20px] font-semibold'>Kompanija</p>
                <div className='flex flex-col space-y-4 text-[#8491A4] items-center justify-center'>
                    <Link className='transition-all ease-in-out delay-75 hover:text-[#1080F7]' to='/'>Pocetna</Link>
                    <Link  className='transition-all ease-in-out delay-75 hover:text-[#1080F7]' to='/o-nama'>O nama</Link>
                </div>
                
            </div>
            <div className='flex flex-col items-center justify-center space-y-2'>
                <p className='text-[#fff] text-[20px] font-semibold'>Drustvene mreze</p>
                <div className='flex flex-col space-y-4 text-[#8491A4]'>
                    <a className='transition-all ease-in-out delay-75 hover:text-[#1080F7]' href="https://instagram.com">Instagram</a>
                    <a  className='transition-all ease-in-out delay-75 hover:text-[#1080F7]' href="https://facebook.com">Facebook</a>
                </div>
                
            </div>
        </div>
      </div>
    </div>
  )
}

export default FooterUp
