import React from 'react'

//assets
import day from '../../Assets/HomePage/day.png'
import quality from '../../Assets/HomePage/quality.png'
import trust from '../../Assets/HomePage/trustworth.png'
import support from '../../Assets/HomePage/support.png'

function Trust() {
  return (
    <div className='w-full bg-[#313C42] py-32'>
        <div className='container mx-auto flex justify-center items-center flex-col space-y-24 px-4 md:px-0'>
            <h1 className='text-[#fff]  font-semibold text-[35px] md:text-[60px] text-center'>Zašto bi trebali da verujete u nas?</h1>
            <div className='flex md:gap-24 gap-10 justify-between items-center flex-col md:flex-row '>
                <div className='gap-10 flex flex-col'>
                    <div className='flex items-start  gap-10 '>
                        <img draggable='false' className='w-[60px] md:w-[80px]' src={day} alt="dan" />
                        <div>
                            <p className='text-[18px] md:text-[28px] text-[#fff] font-semibold'>Brza popravka</p>
                            <p className='text-[#909090] w-[200px] md:w-[400px]'>Popravke mobilnih telefona se obično obavljaju istog dana!</p>
                        </div>
                    </div>
                    <div className='flex items-start gap-10'>
                        <img draggable='false' className='w-[60px] md:w-[80px]' src={quality} alt="trust" />
                        <div>
                            <p className='text-[#fff] text-[18px] md:text-[28px] font-semibold'>Visokokvalitetni delovi</p>
                            <p className='text-[#909090] w-[200px] md:w-[400px]'>Dajemo garanciju na sve naše rezervne delove!</p>
                        </div>
                    </div>
                </div>

                <div className='flex gap-10 flex-col'>

                <div className='flex items-start  gap-10'>
                        <img draggable='false' className='w-[60px] md:w-[80px]' src={trust} alt="dan" />
                        <div>
                            <p className='text-[#fff] text-[16px] md:text-[28px] font-semibold'>Dobro poznata kompanija</p>
                            <p className='text-[#909090] w-[200px] md:w-[400px]'>Preko 7 godina na tržištu.Ocenjeno sa 5 zvezdica više od 5000 puta! </p>
                            
                        </div>
                    </div>
                    <div className='flex items-start  gap-10'>
                        <img draggable='false' className='w-[60px] md:w-[80px]' src={support} alt="trust" />
                        <div>
                            <p className='text-[#fff] text-[16px] md:text-[28px] font-semibold'>Sveobuhvatna briga</p>
                            <p className='text-[#909090] w-[200px] md:w-[300px]'>Skoro uvek dostupan putem e-pošte, VhatsApp-a ili telefona!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Trust