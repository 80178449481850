import React from 'react'

function FooterDown() {
  return (
    <div className='w-full bg-[#1080F7] py-2'>
      <div className='container mx-auto flex justify-center items-center flex-col text-[#fff] font-light text-center text-[14px] md:text-[16px]'>
            <h1>&#169; Autorska prava 2024 od <br/>iMobile Servis - Sva prava zadrzana</h1>
            <p>- Kowy - </p>
      </div>
    </div>
  )
}

export default FooterDown
