import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'

//assets
import logo from '../../Assets/NavBar/logo.png'
import iks from '../../Assets/NavBar/iks.png'
import ham from '../../Assets/NavBar/menu.png'

function MainNavBar() {
  const [toggle, setToggle] = useState(false)


  return (
    <div className='bg-[#1E242F] w-full'>
        <div className='mx-auto container md:px-4 px-4 py-8 flex items-center justify-between '>
            <div>
                <Link to='/'>
                  <img draggable='false' className='md:w-[300px] w-64' src={logo} alt="" />
                </Link>
               
            </div>

            <div className='text-[#fff] xl:gap-24 lg:gap-24 md:gap-12 md:flex items-center justify-center font-medium hidden'>
                <NavLink className='transition-all ease-in-out delay-100 hover:text-[#1080F7]' to='/'>Pocetna</NavLink>
                <NavLink className='transition-all ease-in-out delay-100 hover:text-[#1080F7]' to='/o-nama'>O nama</NavLink>
                <a href='#kontakt' className='border-[1px] rounded-full py-3 px-10 border-[#1080f7] bg-[#1080f7]  text-[#fff]'>Kontakt</a>
            </div>


            <div className='flex items-center justify-center md:hidden'>
                <div>
                  {!toggle ? <img className='invert w-8' src={ham} alt='hammen' onClick={() => setToggle(!toggle)}/> : <img className='invert w-[40px]' src={iks} alt='iks' onClick={() => setToggle(!toggle)}/>}
                </div>
            </div>

            {toggle ?
             <div className='flex justify-center space-y-6 p-2 items-center flex-col border-2 border-[#1080f7] absolute bg-[#1E242F] rounded-3xl top-32 right-0 left-0 bottom-44'>
                 <NavLink onClick={() => setToggle(false)} className='transition-all bg-[#fff] border-[#1080f7] rounded-full py-3 text-[18px] ease-in-out delay-100 border-2 w-full text-center hover:text-[#1080F7]' to='/'>Pocetna</NavLink>
                <NavLink onClick={() => setToggle(false)} className='transition-all bg-[#fff] border-[#1080f7] rounded-full py-3 text-[18px] ease-in-out delay-100 border-2 w-full text-center hover:text-[#1080F7]' to='/o-nama'>O nama</NavLink>
                <a href='#kontakt' onClick={() => setToggle(false)} className='border-[1px] rounded-full w-full text-center py-3 px-10 border-[#1080f7] bg-[#1080f7]  text-[#fff]'>Kontakt</a>
            </div>
             : <></>}
        </div>
        
      
    </div>
  )
}

export default MainNavBar
