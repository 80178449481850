import React from 'react'
import { Outlet } from 'react-router-dom'
import Footer from '../Components/Footer'
import NavBar from '../Components/NavBar'

function RootLayout() {
  return (
    <div>
      <NavBar/>
      <Outlet/>
      <Footer/>
    </div>
  )
}

export default RootLayout
