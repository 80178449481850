import React from 'react'
import { Link } from 'react-router-dom'

//assets
import logo from '../../Assets/NavBar/logo.png'
import Kontakt from '../HomePage/Kontakt'

function Main() {
  return (
    <div className='w-full bg-[#1E242F] py-16'>
      <div className='container mx-auto flex justify-center items-center flex-col text-center space-y-12 text-[#fff] '>
        <h1 className='text-[40px] border-b-2 font-semibold border-[#1080f7]'>O nama</h1>
        <div className='space-y-6 w-[300px] md:w-[900px] text-[18px]'>
        <p >iMobile servis je osnovan aprila 2015. godine u Ćupriji.Kao kompanija nudimo popravke svih vrsta iPhone-a, iPad-a, i Apple Watcha u Ćupriji i oklini Ćuprije
Nudimo i popravke Samsung, Huawei, Redmi i ostalih pametnih telefona.
Cilj kompanije je ponuditi najveće moguće zadovoljstvo uz najbolji kvalitet!</p>


<p>Trajanje popravke vašeg uredjaja može da potraje u većini slučajeva u roku od 1-3 sata ako je rezervni deo na lageru. Ova ekspresna usluga vas ne košta dodatno i standardna je.

Za Apple satove, popravka obično traje 2-4 radna dana u zavisnosti od situacije narudžbine.</p>
<p >iMobile servis se nalazi u ulici Miodraga Novakovića Džudže 2/2 u Ćupriji pored restorana Nole.</p>
        </div>

<div>
    <Link to='/'>
      <img className='w-[300px]' src={logo} alt="" />
    </Link>
 
</div>

<Kontakt/>

      </div>
    </div>
  )
}

export default Main
