import React from 'react'
import BlueBar from './NavBar/BlueBar'
import MainNavBar from './NavBar/MainNavBar'


function NavBar() {
  return (
    <>
      <BlueBar/>
      <MainNavBar/>
    </>
  )
}

export default NavBar
