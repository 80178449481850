import React from 'react'

//assets
import firstimg from '../../Assets/HomePage/company1.png'
import intro from '../../Assets/HomePage/videointro.mp4'

function Company() {
  return (
    <div className='w-full bg-[#1E242F] py-32'>
        <div className='container mx-auto flex items-center justify-center flex-col space-y-24 px-6 md:px-0 '>
            <h1 className='text-[#fff] text-center font-semibold text-[35px] md:text-[60px]'>Vaše zadovoljstvo nam je važno!</h1>
       
          <div className='flex justify-between items-center gap-24 md:flex-row flex-col '>
            <img draggable='false' className='w-[300px] md:w-full lg:w-[400px]' src={firstimg} alt="slicica" />
            <div className='flex flex-col items-center justify-center text-start space-y-12'>
              <p className='text-[#fff] font-semibold text-[55px]'>Kompanija</p>
              <div className='text-[#c9c9c9] text-[18px] space-y-6 text-center md:text-start'>
                  <p className='w-[300px] md:w-[600px]'>Od aprila 2015. godine nudimo popravke svih vrsta iPhone-a, iPad-a, i Apple Watcha u Cupriji i oklini Cuprije</p>
                  <p className='w-[300px] md:w-[600px]'> Nudimo i popravke Samsung, Huawei, Redmi i ostalih pametnih telefona</p>
                  <p className='w-[300px] md:w-[600px]'>Cilj kompanije je ponuditi najveće moguće zadovoljstvo uz najbolji kvalitet!</p>
               
              </div>
              
                

              
            </div>
          </div>


        <div className='flex justify-between flex-col md:flex-row-reverse items-center gap-24 '>
          <video draggable='false' className='hidden md:block rounded-3xl w-[350px] md:w-[600px] lg:w-[400px]' src={intro} muted='muted' loop></video>

          <div className='text-[#c9c9c9] text-[18px] flex flex-col justify-center items-center md:items-start space-y-6 text-center md:text-start px-4 md:px-0'>
              <p className='text-[#fff] font-semibold text-[55px]'>Trajanje popravke?</p>
              <p className='w-[300px] md:w-[600px]'>U većini slučajeva popravka je moguća u roku od 1-3 sata ako je rezervni deo na lageru. Ova ekspresna usluga vas ne košta dodatno i standardna je.</p>
              <p className='w-[300px] md:w-[600px]'>Za Apple satove, popravka obično traje 2-4 radna dana u zavisnosti od situacije narudžbine.</p>
          </div>
          
          

        </div>
       
        </div>
    </div>
  )
}

export default Company