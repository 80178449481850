import React from 'react'

//assets
import bigpic from '../../Assets/HomePage/OBJECTS.png'
import iks from '../../Assets/HomePage/cross.png'
import vreme from '../../Assets/HomePage/half.png'
import dobrovreme from '../../Assets/HomePage/Frame-2.png'

function Apointment() {
  return (
    <div className='w-full bg-[#1E242F] py-24 px-2 md:px-0'>
        <div className='container mx-auto flex justify-center items-center flex-col space-y-32'>
            <h1 className='text-[#fff] text-[40px] md:text-[60px] font-semibold text-center'>Molimo zakažite termin unapred.</h1>
            <div className='flex items-center justify-between gap-36 md:flex-row flex-col'>
                <div>
                    <img draggable='false' className='w-[300px] md:w-full lg:w-[400px]' src={bigpic} alt="bigpc" />
                </div>
                <div className='flex flex-col justify-center items-start space-y-8'>
                    <div className='flex items-center justify-center gap-10'>
                        <img draggable='false' className='rounded-xl ' src={dobrovreme} alt="slicica" />
                        <div>
                            <p className='font-semibold text-[30px] md:text-[40px] lg:text-[30px] text-[#fff]'>Ponedeljak - Petak</p>
                            <p className='text-[#8491A4] text-[20px]'>09:00 - 14:00 / 16:00 - 20:00</p>
                        </div>
                    </div>

                    <div className='flex items-center justify-start gap-10'>
                        <img draggable='false' className='rounded-xl' src={vreme} alt="vremesubota" />
                        <div>
                            <p className='font-semibold text-[30px] md:text-[40px] text-[#fff]'>Subota</p>
                            <p className='text-[#8491A4] text-[20px]'>09:00 - 14:00</p>
                        </div>
                    </div>

                    <div className='flex items-center justify-center gap-10'>
                        <img draggable='false' className='rounded-2xl' src={iks} alt="iksa" />
                        <div >
                            <p className='font-semibold text-[30px] md:text-[40px] text-[#fff]'>Nedelja</p>
                            <p className='text-[#8491A4] text-[20px]'>Zatvoreno</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Apointment