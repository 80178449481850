import React from 'react'
import FooterDown from './Footer/FooterDown'
import FooterUp from './Footer/FooterUp'

function Footer() {
  return (
    <>
        <FooterUp/>
        <FooterDown/>
    </>
  )
}

export default Footer
